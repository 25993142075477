<template>
	<main class="page-container container">
		<header class="page__header mb-60" :class="isMobileMedium ? 'flex-wrap' : ''">
			<app-text
				tag="h1"
				size="26"
				line-height="34"
				weight="600"
				:class="[isMobileMedium ? 'w-100' : '', isMobileMedium ? 'mb-20' : '']"
				class="mr-30 font_Hsemibold"
			>
				{{ $t("allSections") }}
			</app-text>
			<!-- <app-input class="page__header-input" placeholder="Поиск...">
				<template #prepend>
					<img src="../../assets/icons/search.svg" class="color-text-light" alt="" />
				</template>
			</app-input> -->
		</header>

		<div class="section__wrapper mb-80">
			<div class="all-sections">
				<div
					class="all-sections__item"
					:class="isDesktopSmall ? 'mb-30' : 'mb-50'"
					v-for="(item, idx) in allSections"
					:key="idx"
				>
					<div class="all-sections__header">
						<div class="all-sections__icon mr-20">
							<!--							<dynamic-icon :icon="item.icon"/>-->
							<img :src="item.icon" alt="" />
						</div>

						<AppText size="20" weight="600 font_Hsemibold"> {{ item.title }} </AppText>
					</div>
					<div class="all-sections__body">
						<ul class="all-sections__list">
							<li
								class="all-sections__list-item"
								v-for="(child, index) in item.children"
								:key="'all-sections' + index"
							>
								<template v-if="!child.developing">
									<a
										:href="child.path"
										:target="child.hard ? '_blank' : ''"
										class="all-sections__list-link"
									>
										<span class="all-sections__item-text font_Hmedium">{{ child.title }}</span>
									</a>
								</template>
								<template v-else>
									<span class="all-sections__list-link developing">
										<div>
											<span class="all-sections__item-text font_Hmedium">{{ child.title }}</span>
										</div>
										<div class="developingText">
											{{ $t("developing") }}
										</div>
									</span>
								</template>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import AppInput from "../../components/shared-components/AppInput";
import DynamicIcon from "../../components/shared-components/DynamicIcon";

export default {
	name: "index",
	components: { DynamicIcon, AppInput, AppText },
	data() {
		return {
			allSections: [
				{
					title: this.$i18n.t("procurement"),
					icon: require("../../assets/icons/cart.svg"),
					children: [
						{
							title: this.$t("planGraphic"),
							path: "#/plan-graphic",
						},
						{
							title: this.$t("shop"),
							path: "#/product-list",
						},
						// {
						// 	title: this.$t("auction"),
						// 	path: "#/auction",
						// 	developing: true,
						// },
						// {
						// 	title: this.$t("selection"),
						// 	path: "#/selection",
						// 	developing: true,
						// },
						// {
						// 	title: this.$t("tender"),
						// 	path: "#/tender",
						// 	developing: true,
						// },
						// {
						// 	title: this.$t("directContracts"),
						// 	path: "#/direct-purchases",
						// 	developing: false,
						// },
					],
				},

				{
					title: this.$t("operatorServices"),
					icon: require("../../assets/icons/operator.svg"),
					children: [
						{
							title: this.$t("importAlternativeRequest"),
							path: "#/import-alternative-request",
							developing: false,
						},
						// {
						// 	title: this.$t("integration1C"),
						// 	path: "https://didox.uz/"
						// },
						// {
						// 	title: this.$t("dibankFeatures"),
						// 	path: "https://dibank.uz/"
						// },
						// {
						// 	title: this.$t("unitMonitoring"),
						// 	path: "https://didox.uz/"
						// }
					],
				},

				{
					title: this.$t("information"),
					icon: require("../../assets/icons/information.svg"),
					children: [
						{
							title: this.$t("statistics"),
							path: "#/statistics",
						},
						{
							title: this.$t("unifiedRegisterOfTheContract"),
							path: "https://stat-new.cooperation.uz/all-deals",
							hard: true,
						},
						{
							title: this.$t("registerOfActiveTrades"),
							path: "#/active-trades",
						},
						{
							title: this.$t("standardFormsAndContracts"),
							path: "#/standard-forms",
						},
						// {
						// 	title: this.$t("complaintsCommissionDecisions"),
						// 	path: "/",
						// },
						{
							title: this.$t("instructions"),
							path: "#/instructions",
						},
						// {
						// 	title: this.$t("catalog"),
						// 	path: "http://tasniflagich.mf.uz/",
						// },
						{
							title: this.$t("news"),
							path: "#/news",
						},
						{
							title: this.$t("tariffs"),
							path: "#/tariffs",
						},
						// {
						// 	title: this.$t("faq"),
						// 	path: "#/faq",
						// },
					],
				},

				{
					title: this.$t("aboutPortal"),
					icon: require("../../assets/icons/about-company.svg"),
					children: [
						{
							title: this.$t("technicalSupport"),
							path: "",
						},
						{
							title: this.$t("contacts"),
							path: "#/contacts",
						},
					],
				},

				{
					title: this.$t("purchasingRange"),
					icon: require("../../assets/icons/analytics.svg"),
					children: [
						{
							title: this.$t("priceCriteria"),
							path: "#/price-criteria-for-public-procurement",
						},
						{
							title: this.$t("calculator"),
							path: "#/calculator",
						},
					],
				},

				{
					title: this.$t("subjectsOfProcurement"),
					icon: require("../../assets/icons/suitcase.svg"),
					children: [
						{
							title: this.$t("unifiedRegisterOfUnscrupulousPerformers"),
							path: "#/unscrupulous-executors-register",
						},
						{
							title: this.$t("singleSupplierRegister"),
							path: "#/single-supplier",
						},
						{
							title: this.$t("registerOfCorporateCustomers"),
							path: "#/corporate-customers-register",
						},
						{
							title: this.$t("registryOfBudgetCustomers"),
							path: "#/budget-customers-register",
						},
						// {
						// 	title: this.$t("specializedOrganizations"),
						// 	path: "#/specialized-organizations",
						// },
						{
							title: this.$t("registerOfManufacturersDealers"),
							path: "#/single-supplier-register",
						},
						{
							title: this.$t("getAllCompanies"),
							path: "#/all-companies",
						},
					],
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.all-sections {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	-webkit-column-gap: 30px;
	-moz-column-gap: 30px;
	column-gap: 30px;

	&__item {
	}

	&__header {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		margin-top: 10px;
		width: 100%;
	}

	&__icon {
		max-width: 60px;
		width: 100%;
		height: 60px;
		border-radius: 8px;
		background: var(--color-main2);

		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	&__body {
	}

	&__list {
		&-item {
			margin-bottom: 16px;

			&:hover {
				span {
					text-decoration: underline;
					color: var(--color-main2);
				}
			}
		}

		&-link {
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;

			&::before {
				content: ">";
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				left: 0;
				color: var(--color-main2);
				font-size: 16px;
				font-weight: bold;
			}

			span {
				font-size: 14px;
				font-weight: 500;
				display: flex;
				align-items: center;
				margin-left: 15px;
			}
		}
	}
}

@media (max-width: 1000px) {
	.all-sections {
		-webkit-column-count: unset;
		-moz-column-count: unset;
		column-count: unset;

		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 0 20px;
	}

	.all-sections__icon {
		max-height: 50px;
		height: 50px;
	}
}

@media (max-width: 500px) {
	.all-sections {
		padding: 0 20px;
		grid-template-columns: 1fr;
	}
}
.developing {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 15px;
	span {
		cursor: pointer;
	}
	span.all-sections__item-text,
	span.font_Hmedium {
		margin-left: 0;
	}
	.developingText {
		font-size: 0.7rem;
		// color: #26d19e;
	}
}
</style>

